import React from "react";

import Layout from "../components/layout";
import SectionCard from "../components/section-card";
import SEO from "../components/seo";
import Image from 'gatsby-image';

import { css } from "@emotion/core";
import { graphql } from "gatsby";

export interface BlogTemplateProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        date: string;
        author: string;
        description: string;
        slug: string;
      };
      html: string;
    };
    header: {
      childImageSharp: {
        fluid: any;
      }
    }
  };
}

const BlogTemplate: React.FC<BlogTemplateProps> = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout transparentHeaderOnTop={false}>
      <SEO
        title={`Blog | ${frontmatter.title} - ${frontmatter.author}`}
        description={frontmatter.description}
        headerImage={`/headers/${frontmatter.slug}.jpg`}
      />
      <div
        className="container mb-6"
        css={{
          '@media (min-width: 768px)': {
            marginTop: '14em',
          }
        }}>
        <SectionCard>
          <div className="">
            <div className="columns">
              <div className="column">
                <h1 className="mb-2 display-4">{frontmatter.title}</h1>
              </div>
              <div className="column">
                <div
                  css={{
                    maxWidth: 400,
                    margin: '0 auto',
                  }}
                >
                  <Image
                    fluid={data.header.childImageSharp.fluid}
                    alt={frontmatter.title}
                    css={{
                      borderRadius: "1em",
                      "@media (min-width: 768px)": {
                        marginTop: "-8em",
                      },
                    }}
                  />
                </div>
                {/* <img
                  className="img-fluid"
                  css={{
                    borderRadius: "1em",
                    "@media (min-width: 768px)": {
                      marginTop: "-8em",
                    },
                  }}
                  src={`/headers/${frontmatter.slug}.jpg`}
                /> */}
              </div>
            </div>
            <p className="text-secondary mb-2">{frontmatter.date}</p>
            <p>{frontmatter.author}</p>
          </div>
          <div
            className="px-2"
            css={css`
              max-width: 650px;
              margin: 0 auto;

              p,
              li {
                color: #1e1e1e;
                text-align: justify;
              }

              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: #ca4268;
                margin: 2em 0 1em 0;
              }

              img {
                width: 80%;
                display: block;
                margin: 5em auto;
                border-radius: 10px;
                position: relative;
              }

              .gatsby-highlight {
                margin-bottom: 1.5em;
              }
            `}
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </SectionCard>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD-MM-YYYY")
        slug
        title
        author
        description
      }
    }
    header: file(name: { eq: $slug }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default BlogTemplate;
